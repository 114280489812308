<template>
  <div>
    <OurFormationsComp v-bind:mobile="mobile"/>
  </div>
</template>

<script>
import OurFormationsComp from '@/components/desktop/ConferenceFormation/OurFormationsComp'

  export default {
    name: 'ConferenceSportHealth',
    props: ['mobile'],
    components: {
      OurFormationsComp,
    },
  }
</script>
