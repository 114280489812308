<template>
    <v-row justify="center" align="center" no-gutters v-bind:class="mobile ? 'sectionMobile' : 'section'">
      <div class="textOnImage">
        <h1 v-bind:class="mobile ? 'titleOurFormationsMobile' : 'titleOurFormations'" v-html="$t('mainTitle')"></h1>
        <p v-bind:class="mobile ? '' : 'subTitleOurFormations'" v-html="$t('subTitle')"></p>
      </div>
        <v-img
            gradient="to top right, rgba(87,147,158,.7), rgba(87,147,158,.7)"
            class="imageBackground"
            :src="require('@/assets/conferenceFormation/formationBackground.jpg')"
            v-bind:height="mobile ? '500px' : '100%'"
            width="100%"
        />
    </v-row>
</template>

<script>
export default {
  name: 'OurFormationsComp',
  props: ['mobile'],
  data: () => ({}),
}
</script>

<i18n>
{
  "en": {
    "mainTitle": "Our training",
    "subTitle": "Services available soon."
  },
  "fr": {
    "mainTitle": "Nos formations",
    "subTitle": "Services bientôt disponibles."
  }
}
</i18n>

<style scoped>

.section {
  margin-top: -20px;
}

.textOnImage {
  text-align: center;
  color: #FFFFFF;
  z-index: 5;
  position: absolute;
}

.titleOurFormations {
  font-family: Comfortaa, sans-serif;
}

.titleOurFormationsMobile {
  font-size: 23px;
  font-family: Comfortaa, sans-serif;
}

.subTitleOurFormations {
  margin-left: 10px;
}

</style>
